import { CalendarControlsButton } from 'app/components/CalendarControls/_components/CalendarControlsButton';
import { Typography } from '@mui/material';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';

interface OwnProps {
  onClick: () => void;
  date: number;
  active: boolean;
  iconColor?: string;
  buttonBackground?: string;
  buttonHoverBackground?: string;
  highlightedHoverColor?: string;
}

export const CalendarDateButton = ({
  onClick,
  date,
  active,
  iconColor,
  buttonBackground,
  buttonHoverBackground,
  highlightedHoverColor,
}: OwnProps) => {
  return (
    <CalendarControlsButton
      iconColor={iconColor}
      buttonBackground={buttonBackground}
      buttonHoverBackground={buttonHoverBackground}
      highlightedHoverColor={highlightedHoverColor}
      onClick={onClick}
      active={active}
      sx={{
        minWidth: '50px',
        lineHeight: 1.2,
        flexDirection: 'column',
        padding: '5px',
        width: '100%',
      }}
    >
      <Typography
        variant="caption"
        className="highlighted"
        sx={{
          fontWeight: 600,
          lineHeight: 'inherit',
          display: 'block',
          fontSize: 12,
          color: 'navyBlue',
        }}
      >
        {createMomentUtc(date).format('ddd')}
      </Typography>
      <Typography
        variant="caption"
        className="highlighted"
        sx={{
          fontSize: 12,
          display: 'block',
          lineHeight: 'inherit',
          color: 'comet',
          whiteSpace: 'nowrap',
        }}
      >
        {createMomentUtc(date).format('MMM D')}
      </Typography>
    </CalendarControlsButton>
  );
};
