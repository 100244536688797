import React from 'react';

export const SpanishFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_6268)">
        <path
          d="M0 11.9994C0 13.4672 0.264047 14.8733 0.746391 16.1732L12 17.2167L23.2536 16.1732C23.736 14.8733 24 13.4672 24 11.9994C24 10.5315 23.736 9.12545 23.2536 7.82547L12 6.78198L0.746391 7.82547C0.264047 9.12545 0 10.5315 0 11.9994Z"
          fill="#FFDA44"
        />
        <path
          d="M23.2551 7.82587C21.5596 3.2563 17.161 -0.000244141 12.0015 -0.000244141C6.84189 -0.000244141 2.44338 3.2563 0.747864 7.82587H23.2551Z"
          fill="#D80027"
        />
        <path
          d="M0.746887 16.1741C2.4424 20.7436 6.84092 24.0002 12.0005 24.0002C17.1601 24.0002 21.5586 20.7436 23.2541 16.1741H0.746887Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_6268">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
