import React from 'react';

export const ChineseFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1624_30337)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#D80027"
        />
        <path
          d="M6.56719 7.30151L7.60313 10.489H10.9547L8.24531 12.4624L9.28125 15.6499L6.56719 13.6812L3.85313 15.6499L4.89375 12.4624L2.17969 10.489H5.53125L6.56719 7.30151Z"
          fill="#FFDA44"
        />
        <path
          d="M14.227 18.5845L13.4348 17.6095L12.2629 18.0642L12.9426 17.0095L12.1504 16.0298L13.3645 16.3533L14.0488 15.2986L14.1145 16.5548L15.3332 16.8783L14.1566 17.3283L14.227 18.5845Z"
          fill="#FFDA44"
        />
        <path
          d="M15.802 15.7252L16.177 14.5251L15.1504 13.7986L16.4066 13.7798L16.777 12.5798L17.1848 13.7705L18.441 13.7564L17.4332 14.5064L17.8363 15.697L16.8098 14.9705L15.802 15.7252Z"
          fill="#FFDA44"
        />
        <path
          d="M17.9246 8.80649L17.3715 9.93618L18.2715 10.8127L17.0293 10.6346L16.4762 11.7596L16.2605 10.5221L15.0137 10.344L16.1293 9.75806L15.9137 8.51587L16.8137 9.39243L17.9246 8.80649Z"
          fill="#FFDA44"
        />
        <path
          d="M14.2582 5.38501L14.1645 6.63657L15.3316 7.11001L14.1082 7.41001L14.0191 8.66626L13.3582 7.59751L12.1348 7.89751L12.9457 6.93657L12.2801 5.87251L13.4473 6.34595L14.2582 5.38501Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30337">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
