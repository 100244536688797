import React from 'react';

export const CatalanFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4032_99201)">
        <rect y="21.332" width="24" height="2.66667" fill="#FBB41D" />
        <rect y="18.668" width="24" height="2.66667" fill="#D80027" />
        <rect y="16" width="24" height="2.66667" fill="#FBB41D" />
        <rect y="13.3359" width="24" height="2.66667" fill="#D80027" />
        <rect y="10.6641" width="24" height="2.66667" fill="#FBB41D" />
        <rect y="8" width="24" height="2.66667" fill="#D80027" />
        <rect y="5.33203" width="24" height="2.66667" fill="#FBB41D" />
        <rect y="2.66797" width="24" height="2.66667" fill="#D80027" />
        <rect width="24" height="2.66667" fill="#FBB41D" />
      </g>
      <defs>
        <clipPath id="clip0_4032_99201">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
