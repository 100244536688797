import { CustomMarker, TimelineMarkers } from 'app/components/CustomReactCalendarTimeline';

interface OwnProps {
  now: number;
}

export const CustomTimelineMarkers = ({ now }: OwnProps) => {
  return (
    <>
      <TimelineMarkers>
        <CustomMarker date={now}>
          {({ styles }: { styles: any }) => <div style={styles} className="rct-now-time"></div>}
        </CustomMarker>
      </TimelineMarkers>
    </>
  );
};
