import React from 'react';

export const NorwegianFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_8596)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M0.413353 15.1309C1.10767 17.7071 2.6379 19.9399 4.69562 21.521V15.1309H0.413353Z"
          fill="#D80027"
        />
        <path
          d="M10.9563 23.9548C11.3003 23.9844 11.6481 24.0004 11.9998 24.0004C17.5441 24.0004 22.2094 20.2401 23.5864 15.1309H10.9563V23.9548Z"
          fill="#D80027"
        />
        <path
          d="M23.5864 8.87004C22.2094 3.76085 17.5441 0.000488281 11.9998 0.000488281C11.6481 0.000488281 11.3003 0.0165195 10.9563 0.0461445V8.87004H23.5864Z"
          fill="#D80027"
        />
        <path
          d="M4.69562 2.48022C2.6379 4.06132 1.10767 6.29412 0.413353 8.87032H4.69562V2.48022Z"
          fill="#D80027"
        />
        <path
          d="M23.8984 10.4342H9.39131H9.39126V0.284668C8.28229 0.530574 7.23093 0.929152 6.26081 1.45851V10.4341V10.4342H0.101525C0.0347278 10.9466 -5.34058e-05 11.4689 -5.34058e-05 11.9994C-5.34058e-05 12.5299 0.0347278 13.0523 0.101525 13.5646H6.26076H6.26081V22.5403C7.23093 23.0696 8.28229 23.4683 9.39126 23.7142V13.5648V13.5647H23.8984C23.9651 13.0523 23.9999 12.5299 23.9999 11.9994C23.9999 11.4689 23.9651 10.9466 23.8984 10.4342Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8596">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
