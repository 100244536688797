import React from 'react';

export const EstonianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_8509)">
        <path
          d="M-0.000297546 11.9994C-0.000297546 13.4672 0.263749 14.8733 0.746093 16.1733L11.9997 16.695L23.2533 16.1733C23.7357 14.8733 23.9997 13.4672 23.9997 11.9994C23.9997 10.5315 23.7357 9.12546 23.2533 7.82548L11.9997 7.30371L0.746093 7.82543C0.263749 9.12546 -0.000297546 10.5315 -0.000297546 11.9994Z"
          fill="black"
        />
        <path
          d="M11.9997 -0.000244141C6.84009 -0.000244141 2.44157 3.25626 0.746056 7.82587H23.2533C21.5578 3.25626 17.1592 -0.000244141 11.9997 -0.000244141Z"
          fill="#0052B4"
        />
        <path
          d="M23.2533 16.1743H0.746056C2.44157 20.7439 6.84009 24.0004 11.9997 24.0004C17.1592 24.0004 21.5578 20.7439 23.2533 16.1743Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8509">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
