import React from 'react';

export const ThaiFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1624_30611)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M23.2536 7.82593H0.746391C0.264 9.12591 0 10.532 0 11.9998C0 13.4677 0.264 14.8737 0.746391 16.1737H23.2537C23.736 14.8737 24 13.4677 24 11.9998C24 10.532 23.736 9.12591 23.2536 7.82593Z"
          fill="#0052B4"
        />
        <path
          d="M12.0009 -0.000610352C8.36383 -0.000610352 5.10494 1.6177 2.9043 4.17328H21.0975C18.8969 1.6177 15.638 -0.000610352 12.0009 -0.000610352Z"
          fill="#D80027"
        />
        <path
          d="M21.0975 19.826H2.9043C5.10494 22.3816 8.36383 23.9999 12.0009 23.9999C15.638 23.9999 18.8969 22.3816 21.0975 19.826Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30611">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
