import { useController } from 'react-hook-form';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { Heading } from 'app/components/Heading';
import { useTheme } from '@mui/material/styles';
import { useStatus } from 'hooks/useStatus';
import { fileCall } from 'api/app/file';
import { IFile } from 'types/app/file';
import { useFile } from 'hooks/useFile';

interface OwnProps {
  name: string;
  multiple?: boolean;
  accept?: string;
}

export const CustomFileUpload = ({ name, multiple = false, accept }: OwnProps) => {
  const theme = useTheme();
  const { getBlobURL } = useFile();
  const { isReadOnly } = useStatus();

  const { t } = useTranslation();
  const {
    field,
    formState: { errors },
  } = useController({
    name,
  });

  const { value, ...inputProps } = field;
  const [files, setFiles] = useState<IFile[]>(value);

  const { setError, setValue } = useFormContext();
  const maxImages = 16;

  useEffect(() => {
    if (files.length === 0) {
      setValue(name, [], { shouldValidate: true });
    } else {
      setValue(name, files, { shouldValidate: true });
    }
  }, [files, setValue, name]);

  const handleFiles = (files: FileList | File) => {
    if (files instanceof FileList) {
      const filesArray = Array.from(files);

      if (filesArray.length <= maxImages) {
        const filePromises = filesArray.map((file) => fileCall(file));

        Promise.all(filePromises).then((response) => {
          setFiles((prevServerFiles) => [...prevServerFiles, ...response]);
        });
      } else {
        setError(name, {
          type: 'manual',
          message: t('maxImages', { maxImages }),
        });
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      handleFiles(files);
      e.target.value = '';
    }
  };

  const handleOpenFile = async (file: IFile) => {
    const blobURL = await getBlobURL(file);
    const win = window.open(blobURL, '_blank');
    win?.focus();
  };

  const handleRemoveFile = (id: number) => {
    const newImages = files.filter((file) => file.id !== id);
    setFiles(newImages);
  };

  const renderFiles = () => {
    return files.map((file) => (
      <Box key={file.id} sx={{ display: 'flex', gap: '18px' }}>
        <Typography
          onClick={() => handleOpenFile(file)}
          sx={{ color: 'secondary.main', cursor: 'pointer', textDecoration: 'underline' }}
        >
          {`${file.name}.${file.extension}`}
        </Typography>
        <Typography
          sx={{ color: 'accent.red', cursor: 'pointer' }}
          onClick={() => handleRemoveFile(file.id)}
        >
          {t('delete')}
        </Typography>
      </Box>
    ));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Heading>{t('filesAndExtraInfo')}</Heading>
      {files?.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingBottom: '24px' }}>
          {renderFiles()}
        </Box>
      )}
      <Box
        component="input"
        {...inputProps}
        disabled={isReadOnly}
        onChange={handleFileChange}
        type="file"
        id="customFileInput"
        multiple={multiple}
        accept={accept}
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          width: 0,
          height: 0,
        }}
      />
      <Typography
        component="label"
        htmlFor="customFileInput"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'accent.green',
          color: 'accent.green',
          padding: '4px 8px',
          width: 'fit-content',
          fontSize: '12px',
          borderRadius: theme.borderRadius.small,
        }}
      >
        {t('uploadAFile')}
      </Typography>
      <CustomErrorMessage errors={errors} name={name} />
    </Box>
  );
};
