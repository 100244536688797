import { Box } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { NewMuiDatePicker } from 'app/components/NewMuiDatePicker';
import { useTranslation } from 'react-i18next';
import { datesMoment } from 'utils/date-time/dates';
import { useStatus } from 'hooks/useStatus';

interface OwnProps {
  isCustom: boolean;
  currentDate: number;
  handleDateChange: (date: number) => void;
}

export const ModalDateHeader = ({ isCustom, currentDate, handleDateChange }: OwnProps) => {
  const { t } = useTranslation();
  const { isReadOnly } = useStatus();
  const inputFormat = isCustom ? 'MMMM' : datesMoment.ymd;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: isCustom ? 'center' : 'flex-start',
        flexDirection: isCustom ? 'row' : 'column',
      }}
    >
      <Heading>{t('reservationDate')}</Heading>
      <NewMuiDatePicker
        id="date-picker-dialog-second"
        value={currentDate}
        onChange={handleDateChange}
        inputFormat={inputFormat}
        fullWidth={!isCustom}
        isCustom={isCustom}
        disabled={isReadOnly}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: isCustom ? 'transparent' : 'zircon',
            paddingRight: 0,
          },
          ...(isCustom && {
            '& .MuiInputBase-input': {
              caretColor: 'transparent',
              padding: '0 8px 12px 0',
              textAlign: 'right',
              fontSize: '16px',
              fontWeight: 600,
            },
            '&:hover': {
              fieldset: {
                borderColor: 'transparent !important',
              },
            },
            fieldset: {
              borderColor: 'transparent !important',
            },
          }),
        }}
      />
    </Box>
  );
};
