import React from 'react';

export const RussianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_6271)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M23.2539 16.1735C23.7362 14.8735 24.0002 13.4674 24.0002 11.9996C24.0002 10.5317 23.7362 9.12567 23.2539 7.82568H0.746635C0.264291 9.12567 0.000244141 10.5317 0.000244141 11.9996C0.000244141 13.4674 0.264291 14.8735 0.746635 16.1735L12.0002 17.2169L23.2539 16.1735Z"
          fill="#0052B4"
        />
        <path
          d="M12.0003 23.9995C17.1599 23.9995 21.5584 20.743 23.2539 16.1733H0.746704C2.44222 20.743 6.84073 23.9995 12.0003 23.9995Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_6271">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
