import { CustomHeader, TimelineHeaders } from 'app/components/CustomReactCalendarTimeline';
import { timeFormat } from 'utils/date-time/dates';
import { DateFormatKey } from 'types/dates';

interface OwnProps {
  increment: number;
  dateFormatKey: keyof DateFormatKey;
}

export const newHandleTimelineHeaders = ({ increment, dateFormatKey }: OwnProps) => (
  <TimelineHeaders className="sticky">
    <CustomHeader height={40} headerData={{ someData: 'data' }} unit="hour">
      {({ headerContext: { intervals }, getRootProps, getIntervalProps }) => {
        return (
          <div {...getRootProps()}>
            {intervals.map((interval: any, index: number) => {
              const intervalStyle = {
                textAlign: 'center',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              };
              const incrementFactor = 60 / increment;
              if (!(index % incrementFactor)) {
                const intProps = {
                  ...getIntervalProps({
                    interval,
                    style: intervalStyle,
                  }),
                };
                intProps.style.width *= incrementFactor;
                return (
                  <div className="timeslot" {...intProps}>
                    {interval.startTime.format(timeFormat(dateFormatKey))}
                  </div>
                );
              }
              return false;
            })}
          </div>
        );
      }}
    </CustomHeader>
  </TimelineHeaders>
);
