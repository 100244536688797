import React from 'react';

export const EnglishFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_8664)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M2.48069 4.69312C1.53808 5.91951 0.827311 7.33302 0.413452 8.8686H6.65617L2.48069 4.69312Z"
          fill="#0052B4"
        />
        <path
          d="M23.587 8.8688C23.1732 7.33327 22.4624 5.91975 21.5198 4.69336L17.3444 8.8688H23.587Z"
          fill="#0052B4"
        />
        <path
          d="M0.413452 15.1304C0.827359 16.6659 1.53812 18.0794 2.48069 19.3058L6.65603 15.1304H0.413452Z"
          fill="#0052B4"
        />
        <path
          d="M19.3055 2.47988C18.0791 1.53727 16.6657 0.826504 15.1301 0.412598V6.65527L19.3055 2.47988Z"
          fill="#0052B4"
        />
        <path
          d="M4.69395 21.5179C5.92035 22.4605 7.33386 23.1712 8.8694 23.5852V17.3425L4.69395 21.5179Z"
          fill="#0052B4"
        />
        <path
          d="M8.86935 0.412598C7.33381 0.826504 5.9203 1.53727 4.69395 2.47983L8.86935 6.65522V0.412598Z"
          fill="#0052B4"
        />
        <path
          d="M15.1301 23.5852C16.6656 23.1712 18.0791 22.4605 19.3055 21.5179L15.1301 17.3425V23.5852Z"
          fill="#0052B4"
        />
        <path
          d="M17.3444 15.1304L21.5198 19.3058C22.4624 18.0795 23.1732 16.6659 23.587 15.1304H17.3444Z"
          fill="#0052B4"
        />
        <path
          d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
          fill="#D80027"
        />
        <path
          d="M15.1304 15.1312L20.4852 20.486C20.7315 20.2398 20.9665 19.9824 21.1906 19.7156L16.6062 15.1311H15.1304V15.1312Z"
          fill="#D80027"
        />
        <path
          d="M8.86952 15.1311H8.86943L3.51466 20.4859C3.76085 20.7322 4.01824 20.9671 4.28505 21.1912L8.86952 16.6067V15.1311Z"
          fill="#D80027"
        />
        <path
          d="M8.86938 8.86911V8.86902L3.51456 3.51416C3.26828 3.76035 3.03335 4.01774 2.80919 4.28455L7.3937 8.86907H8.86938V8.86911Z"
          fill="#D80027"
        />
        <path
          d="M15.1304 8.87029L20.4853 3.51539C20.2391 3.26911 19.9817 3.03417 19.7149 2.81006L15.1304 7.39457V8.87029Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8664">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
