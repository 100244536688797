import React from 'react';

export const RomanianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_8613)">
        <path
          d="M16.1741 0.745367C14.8741 0.26307 13.4681 -0.000976556 12.0002 -0.000976556C10.5324 -0.00102343 9.12633 0.26307 7.82635 0.745367L6.78287 11.999L7.82635 23.2526C9.12629 23.735 10.5323 23.999 12.0002 23.999C13.468 23.999 14.8741 23.735 16.1741 23.2526L17.2176 11.999L16.1741 0.745367Z"
          fill="#FFDA44"
        />
        <path
          d="M23.9997 11.9995C23.9997 6.83993 20.7432 2.44132 16.1736 0.74585V23.2532C20.7432 21.5575 23.9997 17.1591 23.9997 11.9995Z"
          fill="#D80027"
        />
        <path
          d="M0.000160217 11.9994C0.000160217 17.159 3.25666 21.5575 7.82618 23.2531L7.82622 0.74585C3.25666 2.44132 0.000160217 6.83979 0.000160217 11.9994Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8613">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
