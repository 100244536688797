import React from 'react';

export const EthiopianFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1624_30560)">
        <path
          d="M0 12C0 13.4679 0.264047 14.874 0.746437 16.174L12 17.2173L23.2536 16.1739C23.736 14.874 24 13.4679 24 12C24 10.5522 23.7435 9.1644 23.2737 7.87942L12 6.78259L0.726281 7.87947C0.256453 9.1644 0 10.5522 0 12Z"
          fill="#FFDA44"
        />
        <path
          d="M11.9997 24.0003C17.1593 24.0003 21.5578 20.7438 23.2533 16.1742H0.746094C2.44161 20.7438 6.84012 24.0003 11.9997 24.0003Z"
          fill="#D80027"
        />
        <path
          d="M11.9997 -0.000366211C6.84013 -0.000366211 2.44161 3.25613 0.746094 7.82574H23.2534C21.5578 3.25613 17.1593 -0.000366211 11.9997 -0.000366211Z"
          fill="#6DA544"
        />
        <path
          d="M11.9989 17.7399C15.1685 17.7399 17.738 15.1704 17.738 12.0007C17.738 8.8311 15.1685 6.2616 11.9989 6.2616C8.82927 6.2616 6.25977 8.8311 6.25977 12.0007C6.25977 15.1704 8.82927 17.7399 11.9989 17.7399Z"
          fill="#0052B4"
        />
        <path
          d="M11.999 7.5564L13.035 10.7449H16.393L13.6753 12.7156L14.7195 15.9136L11.999 13.9336L9.28269 15.9099L10.3227 12.7156L7.60742 10.7449H10.963L11.999 7.5564Z"
          fill="#FFDA44"
        />
        <path
          d="M16.1308 12.7938L12.8441 11.7258L14.8753 8.93018L14.0312 8.31677L11.9999 11.1126L9.96872 8.31682L9.1245 8.93013L11.1557 11.726L7.86914 12.7938L8.19155 13.7862L11.4782 12.7184V16.1741H12.5217V12.7183L15.8082 13.7862L16.1308 12.7938Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30560">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
