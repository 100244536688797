import { useEffect, useCallback, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { ReactComponent as ChevronLeftIcon } from 'images/icons/ic-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'images/icons/ic-chevron-right.svg';
import { CalendarControlsButton } from 'app/components/CalendarControls/_components/CalendarControlsButton';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { useScreenSize } from 'hooks/useScreenSize';
import { CustomOutlinedButton } from 'app/components/Buttons/CustomOutlinedButton';
import { CalendarDateButton } from 'app/components/DateControl/_components/CalendarDateButton';
import { ModalDateHeader } from 'app/components/DateControl/_components/ModalDateHeader';
import { CalendarButtons } from 'app/components/DateControl/_components/CalendarButtons';
import appActions from 'redux/actions/app/app';
import calendarActions from 'redux/actions/calendar';
import moment from 'moment/moment';
import { timestamp } from 'utils/date-time/timestamp';
import { useStatus } from 'hooks/useStatus';
import { useTheme } from '@mui/material/styles';

export enum DateControlVariants {
  Calendar = 'calendar',
  Modal = 'modal',
}

interface OwnProps {
  variant: DateControlVariants;
}

interface DateControlBreakpoints {
  show_sidebars?: boolean;
  isMax500: boolean;
  isBetween501And600: boolean;
  isBetween992And1080: boolean;
  isBetween1081And1170: boolean;
}

export const DateControl = ({ variant }: OwnProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const currentDate = useAppSelector((state) => state.app.app.current_date);
  const displayedDates = useAppSelector((state) => state.app.app.displayed_dates);
  const isCalendar = variant === DateControlVariants.Calendar;
  const isModal = variant === DateControlVariants.Modal;
  const show_sidebars = useAppSelector((state) => state.app.preferences?.show_sidebars);
  const today = createMomentUtc().startOf('day').unix();
  const [startDate, setStartDate] = useState(currentDate);
  const [isTodaySelected, setIsTodaySelected] = useState(false);
  const openingHours = useAppSelector((state) => state.calendar.restaurant.settings.open_hours);

  const { isReadOnly } = useStatus();
  const {
    isMobile,
    isMax500,
    isBetween501And600,
    isMax600,
    isBetween992And1080,
    isBetween1081And1170,
  } = useScreenSize();

  useEffect(() => {
    const dates = generateDisplayedDates(startDate, {
      show_sidebars,
      isMax500,
      isBetween501And600,
      isBetween992And1080,
      isBetween1081And1170,
    });

    dispatch(appActions.updateDisplayedDates(dates));
  }, [
    show_sidebars,
    startDate,
    isMobile,
    isMax500,
    isBetween501And600,
    isBetween992And1080,
    isBetween1081And1170,
    isCalendar,
  ]);

  useEffect(() => {
    if (isTodaySelected) {
      setIsTodaySelected(false);
      return;
    }

    if (
      currentDate < displayedDates[0] ||
      currentDate > displayedDates[displayedDates.length - 1]
    ) {
      setStartDate(currentDate);
      const newDates = generateDisplayedDates(currentDate, {
        show_sidebars,
        isMax500,
        isBetween501And600,
        isBetween992And1080,
        isBetween1081And1170,
      });
      dispatch(appActions.updateDisplayedDates(newDates));
    }
  }, [
    currentDate,
    displayedDates,
    show_sidebars,
    isMax500,
    isBetween501And600,
    isBetween992And1080,
    isBetween1081And1170,
  ]);

  const generateDisplayedDates = useCallback(
    (startDate: number, breakpoints: DateControlBreakpoints) => {
      const {
        show_sidebars,
        isMax500,
        isBetween501And600,
        isBetween992And1080,
        isBetween1081And1170,
      } = breakpoints;

      let daysToShow;
      switch (true) {
        case !show_sidebars:
          daysToShow = 7;
          if (isMax500) {
            daysToShow = 3;
          }
          break;
        case isMax500: {
          daysToShow = 3;
          break;
        }
        case isBetween501And600 || isBetween992And1080: {
          daysToShow = 5;
          break;
        }
        case isBetween1081And1170: {
          daysToShow = 6;
          break;
        }
        default: {
          daysToShow = 7;
        }
      }

      return Array.from({ length: daysToShow }, (_, index) =>
        createMomentUtc(startDate).clone().add(index, 'days').unix(),
      );
    },
    [],
  );

  const changeDateByFactor = useCallback(
    async (changeFactor: number) => {
      const newTimestamp = createMomentUtc(currentDate)
        .add(changeFactor, 'days')
        .startOf('day')
        .unix();

      const oldCurrentDate = moment(timestamp(newTimestamp).toMilliseconds);
      await dispatch(calendarActions.changeCalendarDate(oldCurrentDate, false));
      await dispatch(appActions.changeCurrentDate(newTimestamp));
    },
    [currentDate, dispatch],
  );

  const handleDateChange = useCallback(
    async (newTimestamp: number) => {
      const oldCurrentDate = moment(timestamp(newTimestamp).toMilliseconds);
      await dispatch(calendarActions.changeCalendarDate(oldCurrentDate, false));
      await dispatch(appActions.changeCurrentDate(newTimestamp));
    },
    [dispatch],
  );

  const handleStartDateAndCurrentDate = async (newTimestamp: number) => {
    await handleDateChange(newTimestamp);
  };

  const handleTodayDate = async (today: number) => {
    setStartDate(today);
    setIsTodaySelected(true);
    await handleDateChange(today);
  };

  const handlePrevDate = async () => {
    const minDate = displayedDates[0];
    const newDate = createMomentUtc(minDate).subtract(1, 'days').unix();
    if (currentDate > minDate) {
      await changeDateByFactor(-1);
    } else {
      setStartDate(createMomentUtc(startDate).subtract(1, 'days').unix());
      dispatch(appActions.updateDisplayedDates([newDate, ...displayedDates]));
      await changeDateByFactor(-1);
    }
  };

  const handleNextDate = async () => {
    const maxDate = displayedDates[displayedDates.length - 1];
    const newDate = createMomentUtc(maxDate).add(1, 'days').unix();
    if (currentDate < maxDate) {
      await changeDateByFactor(1);
    } else {
      setStartDate(createMomentUtc(startDate).add(1, 'days').unix());
      dispatch(appActions.updateDisplayedDates([...displayedDates, newDate]));
      await changeDateByFactor(1);
    }
  };

  const isCustomDatePicker = isModal && !isMax600;

  if (Object.keys(openingHours).length === 0) {
    // Do not allow changing days if we don't have information from API about opening hours.
    return <Skeleton variant={'rounded'} height={40} width={300} />;
  }

  return (
    <Box
      sx={
        isCalendar
          ? {
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '8px',
              width: { xs: '100%', md: 'fit-content' },
              justifyContent: { xs: 'space-between', md: 'flex-start' },
            }
          : {}
      }
    >
      {isCalendar && (
        <CalendarButtons
          handleDateChange={handleStartDateAndCurrentDate}
          currentDate={currentDate}
          today={today}
          handleTodayDate={() => handleTodayDate(today)}
        />
      )}
      {isModal && (
        <ModalDateHeader
          isCustom={isCustomDatePicker}
          currentDate={currentDate}
          handleDateChange={handleStartDateAndCurrentDate}
        />
      )}
      {isCalendar && (
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <CalendarControlsButton
            onClick={handlePrevDate}
            buttonHoverBackground={theme.palette.secondary.main}
            iconColor={theme.palette.white}
          >
            <ChevronLeftIcon />
          </CalendarControlsButton>
          {displayedDates.map((date, index) => (
            <CalendarDateButton
              key={index}
              date={date}
              onClick={() => handleDateChange(date)}
              active={date === currentDate}
              highlightedHoverColor={theme.palette.white}
              buttonBackground={
                date === currentDate ? theme.palette.secondary.main : theme.palette.brandWhiteDark
              }
              buttonHoverBackground={
                date === currentDate ? theme.palette.secondary.main : theme.palette.secondary.main
              }
            />
          ))}
          <CalendarControlsButton
            onClick={handleNextDate}
            buttonHoverBackground={theme.palette.secondary.main}
            iconColor={theme.palette.white}
          >
            <ChevronRightIcon />
          </CalendarControlsButton>
        </Box>
      )}
      {isCustomDatePicker && (
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <CustomOutlinedButton
            onClick={handlePrevDate}
            icon={<ChevronLeftIcon />}
            sx={{ minWidth: '40px', flex: 0 }}
            disabled={isReadOnly}
          />
          {displayedDates.map((date, index) => (
            <CustomOutlinedButton
              key={index}
              onClick={() => handleDateChange(date)}
              firstText={createMomentUtc(date).format('ddd')}
              secondText={createMomentUtc(date).format('MMM D')}
              isSelected={date === currentDate}
              disabled={isReadOnly}
            />
          ))}
          <CustomOutlinedButton
            onClick={handleNextDate}
            icon={<ChevronRightIcon />}
            sx={{ minWidth: '40px', flex: 0 }}
            disabled={isReadOnly}
          />
        </Box>
      )}
    </Box>
  );
};
