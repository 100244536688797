import { CalendarControlsItem } from 'app/components/CalendarControls/_components/CalendarControlsItem';
import { CalendarControlsButton } from 'app/components/CalendarControls/_components/CalendarControlsButton';
import { NewMuiDatePicker } from 'app/components/NewMuiDatePicker';
import { ReactComponent as HomeIcon } from 'images/icons/ic-home-v2.svg';
import { Box } from '@mui/material';

interface OwnProps {
  handleDateChange: (date: number) => void;
  handleTodayDate: () => void;
  currentDate: number;
  today: number;
}

export const CalendarButtons = ({ handleDateChange, handleTodayDate, currentDate }: OwnProps) => {
  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <CalendarControlsItem>
        <CalendarControlsButton onClick={handleTodayDate}>
          <HomeIcon width={20} height={20} />
        </CalendarControlsButton>
      </CalendarControlsItem>
      <CalendarControlsItem>
        <NewMuiDatePicker
          id="date-picker-dialog"
          hideInput
          value={currentDate}
          iconWidth={20}
          iconHeight={20}
          onChange={handleDateChange}
        />
      </CalendarControlsItem>
    </Box>
  );
};
