import React from 'react';

export const GreekFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_8523)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M11.9997 8.86995H23.5863C23.2854 7.75367 22.8281 6.70175 22.2384 5.7395H11.9997V8.86995Z"
          fill="#338AF3"
        />
        <path
          d="M4.53062 21.3914H19.4703C20.5654 20.5192 21.5046 19.4597 22.2391 18.261H1.76176C2.49634 19.4597 3.43552 20.5192 4.53062 21.3914Z"
          fill="#338AF3"
        />
        <path
          d="M4.17437 2.90381C3.23096 3.7162 2.41538 4.67287 1.76176 5.73956H4.17437V2.90381Z"
          fill="#338AF3"
        />
        <path
          d="M12.0001 12.0001C12.0001 10.7778 12.0001 9.75482 12.0001 8.86963H7.30449V12.0001H4.17404V8.86963H0.413536C0.14452 9.86779 0.000144958 10.9169 0.000144958 12.0001C0.000144958 13.0832 0.14452 14.1324 0.413536 15.1305H23.5868C23.8558 14.1324 24.0001 13.0832 24.0001 12.0001H12.0001Z"
          fill="#338AF3"
        />
        <path
          d="M12.0005 0.000732422C10.3342 0.000732422 8.74722 0.340717 7.30487 0.954545V5.73987H12.0005C12.0005 4.64712 12.0005 3.71773 12.0005 2.60942H19.4704C17.4205 0.976857 14.8247 0.000732422 12.0005 0.000732422Z"
          fill="#338AF3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8523">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
