import React from 'react';

export const LithuanianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_6280)">
        <path
          d="M23.254 16.1742C23.7364 14.8743 24.0004 13.4682 24.0004 12.0003C24.0004 10.5325 23.7364 9.12638 23.254 7.82644L12.0004 6.78296L0.746757 7.82644C0.264366 9.12638 0.000366211 10.5325 0.000366211 12.0003C0.000366211 13.4682 0.264366 14.8743 0.746757 16.1742L12.0004 17.2177L23.254 16.1742Z"
          fill="#6DA544"
        />
        <path
          d="M23.2538 7.82562C21.5583 3.25601 17.1598 -0.000488281 12.0002 -0.000488281C6.84061 -0.000488281 2.4421 3.25601 0.746582 7.82562H23.2538Z"
          fill="#FFDA44"
        />
        <path
          d="M12.0003 24.0009C17.1599 24.0009 21.5584 20.7444 23.2539 16.1748H0.746704C2.44222 20.7444 6.84073 24.0009 12.0003 24.0009Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_6280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
