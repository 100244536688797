import { ReactComponent as ChevronDownIcon } from 'images/icons/ic-chevron-down-v2.svg';
import { IconButton } from '@mui/material';

interface IProps {
  room: any;
  onClick: (id: number) => void;
  isExpanded: boolean;
}

export const RoomItem = ({ room, onClick, isExpanded }: IProps) => {
  return (
    <div className="row-root" onClick={() => onClick(room.id)}>
      <div className="row-root-content">
        <span className="table-title">{room.name}</span>
        <IconButton
          className={`row-root__button ${!isExpanded ? 'row-root__button--is-open' : ''}`}
        >
          <ChevronDownIcon />
        </IconButton>
      </div>
    </div>
  );
};
