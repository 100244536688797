import React from 'react';

export const LatvianFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1624_30554)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M12.0007 -0.000732422C6.45634 -0.000732422 1.79106 3.75963 0.414062 8.86881H23.5873C22.2103 3.75963 17.545 -0.000732422 12.0007 -0.000732422Z"
          fill="#A2001D"
        />
        <path
          d="M12.0007 23.9986C17.545 23.9986 22.2103 20.2382 23.5873 15.129H0.414062C1.79106 20.2382 6.45634 23.9986 12.0007 23.9986Z"
          fill="#A2001D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30554">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
