import React from 'react';

export const ItalianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_8544)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M23.9999 11.9992C23.9999 6.83964 20.7434 2.44112 16.1738 0.745605V23.2529C20.7434 21.5573 23.9999 17.1588 23.9999 11.9992Z"
          fill="#D80027"
        />
        <path
          d="M-0.000130653 11.9997C-0.000130653 17.1593 3.25637 21.5578 7.82598 23.2533V0.746094C3.25637 2.44161 -0.000130653 6.84013 -0.000130653 11.9997Z"
          fill="#6DA544"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8544">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
