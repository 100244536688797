import { ReactNode } from 'react';
import { Box, SxProps, Typography, InputLabel } from '@mui/material';

interface OwnProps {
  label?: string | null;
  defaultLabel?: string | null;
  id?: string;
  name?: string;
  description?: string | null;
  sx?: SxProps;
  additionalLabel?: string;
  icon?: ReactNode;
  isSmallerVariant?: boolean;
}

export const CustomLabel = ({
  label,
  icon,
  defaultLabel,
  id,
  name,
  description,
  sx,
  additionalLabel,
  isSmallerVariant,
}: OwnProps) => {
  if (!label && !defaultLabel) return null;
  const defaultLabelFontSize = 15;

  return (
    <>
      {defaultLabel ? (
        <InputLabel
          sx={{
            color: 'navyBlue',
            fontSize: defaultLabelFontSize,
            transform: `translate(${15 - 3}px, -9px) scale(0.75)`,
          }}
        >
          {defaultLabel}
        </InputLabel>
      ) : (
        <Box
          sx={{
            marginBottom: '12px',
            display: 'flex',
            flexDirection: 'column',
            ...sx,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: isSmallerVariant ? 'comet' : 'navyBlue',
              }}
            >
              {icon}
              <Typography
                component="label"
                htmlFor={id || name}
                sx={{
                  fontWeight: isSmallerVariant ? 500 : 600,
                  wordBreak: 'break-word',
                  fontSize: isSmallerVariant ? 14 : 16,
                }}
              >
                {label}
              </Typography>
            </Box>
            {additionalLabel && (
              <Typography sx={{ fontSize: '12px', color: 'comet', fontWeight: 600 }}>
                {additionalLabel}
              </Typography>
            )}
          </Box>
          {description && (
            <Typography sx={{ color: 'comet', wordBreak: 'break-word', fontSize: '12px' }}>
              {description}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
