import React from 'react';

export const JapaneseFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_6259)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M12 17.2177C14.8815 17.2177 17.2174 14.8818 17.2174 12.0003C17.2174 9.11886 14.8815 6.78296 12 6.78296C9.11855 6.78296 6.78265 9.11886 6.78265 12.0003C6.78265 14.8818 9.11855 17.2177 12 17.2177Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_6259">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
