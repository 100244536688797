import React from 'react';

export const DanishFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9.39195 10.435H23.899C23.132 4.54721 18.0975 0.000244141 12.0006 0.000244141C11.1046 0.000244141 10.2319 0.099291 9.39191 0.285479V10.435H9.39195Z"
        fill="#D80027"
      />
      <path
        d="M6.26144 10.4359V1.46021C2.96646 3.25805 0.607611 6.55575 0.102158 10.4359H6.26144V10.4359Z"
        fill="#D80027"
      />
      <path
        d="M6.26095 13.5657H0.101715C0.607169 17.4458 2.96601 20.7435 6.261 22.5413L6.26095 13.5657Z"
        fill="#D80027"
      />
      <path
        d="M9.39191 13.5657V23.7152C10.2319 23.9014 11.1046 24.0005 12.0006 24.0005C18.0975 24.0005 23.132 19.4535 23.899 13.5657H9.39191V13.5657Z"
        fill="#D80027"
      />
    </svg>
  );
};
