import React from 'react';

export const CzechFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4032_99267)">
        <path
          d="M12 23.9999C18.6273 23.9999 23.9999 18.6273 23.9999 12C23.9999 5.37256 18.6273 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6273 5.37256 23.9999 12 23.9999Z"
          fill="#F0F0F0"
        />
        <path
          d="M12 -1C5.86617 -1 0.420601 4.10644 -3 12H27C23.5795 4.10631 18.1338 -1 12 -1Z"
          fill="#ECECEC"
        />
        <path
          d="M12 25C18.1339 25 23.5794 19.8936 27 12H-3C0.420519 19.8937 5.86615 25 12 25Z"
          fill="#D7141A"
        />
        <path
          d="M3.51468 3.51562C-1.17156 8.20186 -1.17156 15.7999 3.51468 20.4862C5.45122 18.5497 7.31366 16.6872 11.9999 12.0009L3.51468 3.51562Z"
          fill="#11457E"
        />
        <path
          d="M4.85646 8.87109L5.63336 11.2624H8.14802L6.11379 12.7405L6.89069 15.1319L4.85646 13.6539L2.82213 15.1319L3.59922 12.7405L1.56494 11.2624H4.07941L4.85646 8.87109Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_4032_99267">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
