import React from 'react';

export const HungarianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_8535)">
        <path
          d="M0.745982 7.82522C0.263639 9.12525 -0.000408173 10.5313 -0.000408173 11.9991C-0.000408173 13.467 0.263639 14.8731 0.745982 16.173L11.9996 17.2165L23.2532 16.173C23.7355 14.8731 23.9996 13.467 23.9996 11.9991C23.9996 10.5313 23.7355 9.12525 23.2532 7.82522L11.9996 6.78174L0.745982 7.82522Z"
          fill="#F0F0F0"
        />
        <path
          d="M12.0004 -0.000976562C6.84085 -0.000976562 2.44229 3.25552 0.746822 7.82513H23.2541C21.5585 3.25552 17.16 -0.000976562 12.0004 -0.000976562Z"
          fill="#D80027"
        />
        <path
          d="M12.0004 23.9992C17.16 23.9992 21.5585 20.7427 23.254 16.1731H0.746822C2.44229 20.7427 6.84085 23.9992 12.0004 23.9992Z"
          fill="#6DA544"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8535">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
