import React from 'react';

export const CroatianFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1749_15815)">
        <path
          d="M24 11.9999C24 10.532 23.736 9.1259 23.2536 7.82596L12 7.3042L0.746391 7.82592C0.264047 9.1259 0 10.532 0 11.9999C0 13.4677 0.264047 14.8738 0.746391 16.1737L12 16.6955L23.2536 16.1738C23.736 14.8738 24 13.4677 24 11.9999Z"
          fill="#F0F0F0"
        />
        <path
          d="M11.9997 23.9995C17.1593 23.9995 21.5578 20.743 23.2533 16.1733H0.746094C2.44161 20.743 6.84013 23.9995 11.9997 23.9995Z"
          fill="#0052B4"
        />
        <path
          d="M0.746094 7.8266H23.2534C21.5578 3.25699 17.1593 0.000488281 11.9997 0.000488281C6.84012 0.000488281 2.44161 3.25699 0.746094 7.8266Z"
          fill="#D80027"
        />
        <path
          d="M15.1299 8.34753H13.043L13.3908 5.7388L14.7821 4.69531L16.1734 5.7388V7.82576L15.1299 8.34753Z"
          fill="#338AF3"
        />
        <path
          d="M8.86961 8.34802H10.9566L10.6087 5.73928L9.21742 4.6958L7.82617 5.73928V7.82625L8.86961 8.34802Z"
          fill="#338AF3"
        />
        <path
          d="M13.3901 8.34777H10.6074V5.73904L11.9988 4.69556L13.3901 5.73904V8.34777Z"
          fill="#0052B4"
        />
        <path
          d="M7.82617 7.82568V13.5648C7.82617 14.9313 8.48627 16.1466 9.50439 16.9085L10.2348 16.7257L11.1566 17.653C11.4292 17.7091 11.7112 17.7387 12.0001 17.7387C12.2875 17.7387 12.5681 17.7095 12.8392 17.6539L13.8914 16.7778L14.4957 16.9068C15.5134 16.145 16.174 14.9309 16.174 13.5648V7.82568H7.82617Z"
          fill="#F0F0F0"
        />
        <path d="M9.49572 7.82593H7.82617V9.49548H9.49572V7.82593Z" fill="#D80027" />
        <path d="M12.8356 7.82593H11.166V9.49548H12.8356V7.82593Z" fill="#D80027" />
        <path d="M16.1735 7.82593H14.5039V9.49548H16.1735V7.82593Z" fill="#D80027" />
        <path d="M11.1656 9.49487H9.49609V11.1644H11.1656V9.49487Z" fill="#D80027" />
        <path d="M14.5035 9.49487H12.834V11.1644H14.5035V9.49487Z" fill="#D80027" />
        <path d="M9.49572 11.1638H7.82617V12.8334H9.49572V11.1638Z" fill="#D80027" />
        <path d="M11.1656 12.8347H9.49609V14.5043H11.1656V12.8347Z" fill="#D80027" />
        <path d="M12.8356 11.1638H11.166V12.8334H12.8356V11.1638Z" fill="#D80027" />
        <path d="M16.1735 11.1638H14.5039V12.8334H16.1735V11.1638Z" fill="#D80027" />
        <path d="M14.5035 12.8347H12.834V14.5043H14.5035V12.8347Z" fill="#D80027" />
        <path d="M12.8356 14.5039H11.166V16.1734H12.8356V14.5039Z" fill="#D80027" />
        <path
          d="M9.49542 14.5039H7.93359C8.07684 15.1245 8.35847 15.6924 8.74462 16.1734H9.49542V14.5039Z"
          fill="#D80027"
        />
        <path
          d="M14.5039 16.1737H15.2547C15.6409 15.6927 15.9224 15.1247 16.0657 14.5042H14.5039V16.1737Z"
          fill="#D80027"
        />
        <path
          d="M9.49609 16.1731V16.9016C9.98167 17.267 10.5491 17.529 11.1656 17.6547V16.1731H9.49609Z"
          fill="#D80027"
        />
        <path
          d="M12.834 16.1731V17.6547C13.4505 17.5291 14.0179 17.267 14.5035 16.9016V16.1731H12.834Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1749_15815">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
