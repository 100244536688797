import React from 'react';

export const IcelandicFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1624_30569)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M0.414062 15.1301C1.10838 17.7063 2.63861 19.9392 4.69633 21.5202V15.1301H0.414062Z"
          fill="#0052B4"
        />
        <path
          d="M10.957 23.954C11.301 23.9837 11.6489 23.9997 12.0005 23.9997C17.5448 23.9997 22.2101 20.2393 23.5871 15.1301H10.957V23.954Z"
          fill="#0052B4"
        />
        <path
          d="M23.5871 8.86918C22.2101 3.75999 17.5448 -0.000366211 12.0005 -0.000366211C11.6489 -0.000366211 11.301 0.015665 10.957 0.04529V8.86918H23.5871Z"
          fill="#0052B4"
        />
        <path
          d="M4.69633 2.47937C2.63861 4.06046 1.10838 6.29326 0.414062 8.86946H4.69633V2.47937Z"
          fill="#0052B4"
        />
        <path
          d="M23.8984 10.4352H9.39136H9.39131V0.285645C8.28234 0.531551 7.23098 0.930129 6.26086 1.45949V10.4352H0.101578C0.0347813 10.9476 0 11.4699 0 12.0004C0 12.5309 0.0347813 13.0533 0.101578 13.5656H6.26081H6.26086V22.5413C7.23098 23.0706 8.28234 23.4693 9.39131 23.7151V13.5658V13.5657H23.8984C23.9651 13.0533 24 12.5309 24 12.0004C24 11.4699 23.9651 10.9475 23.8984 10.4352Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30569">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
