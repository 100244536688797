import React from 'react';

export const AzerbaijanFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_8448)">
        <path
          d="M23.9996 12.0001C23.9996 10.5323 23.7356 9.12615 23.2532 7.82621L11.9996 7.30444L0.745979 7.82616C0.263635 9.12614 -0.000411987 10.5323 -0.000411987 12.0001C-0.000411987 13.4679 0.263635 14.874 0.745979 16.174L11.9996 16.6957L23.2532 16.174C23.7356 14.874 23.9996 13.4679 23.9996 12.0001Z"
          fill="#D80027"
        />
        <path
          d="M12.0003 23.9995C17.1599 23.9995 21.5584 20.743 23.2539 16.1733H0.746719C2.44224 20.743 6.84075 23.9995 12.0003 23.9995Z"
          fill="#6DA544"
        />
        <path
          d="M0.746719 7.82513H23.254C21.5584 3.25552 17.1599 -0.000976562 12.0003 -0.000976562C6.84075 -0.000976562 2.44224 3.25552 0.746719 7.82513Z"
          fill="#338AF3"
        />
        <path
          d="M12.2605 15.3912C10.3876 15.3912 8.86922 13.8728 8.86922 11.9999C8.86922 10.1269 10.3876 8.60855 12.2605 8.60855C12.8445 8.60855 13.394 8.75621 13.8737 9.01618C13.1211 8.28019 12.0919 7.82593 10.9562 7.82593C8.65097 7.82593 6.7823 9.69465 6.7823 11.9998C6.7823 14.305 8.65102 16.1737 10.9562 16.1737C12.092 16.1737 13.1212 15.7194 13.8737 14.9835C13.394 15.2435 12.8445 15.3912 12.2605 15.3912Z"
          fill="#F0F0F0"
        />
        <path
          d="M14.8695 9.65088L15.3187 10.9142L16.5297 10.3385L15.954 11.5495L17.2173 11.9987L15.954 12.448L16.5297 13.6589L15.3187 13.0832L14.8695 14.3465L14.4204 13.0832L13.2093 13.6589L13.7851 12.448L12.5217 11.9987L13.7851 11.5495L13.2093 10.3385L14.4204 10.9142L14.8695 9.65088Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8448">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
