import React from 'react';

export const FinnishFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_8514)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M23.8986 10.4347H9.39153H9.39148V0.285156C8.28251 0.531062 7.23115 0.929641 6.26103 1.459V10.4346V10.4347H0.101746C0.0349491 10.9471 0.000167847 11.4694 0.000167847 11.9999C0.000167847 12.5304 0.0349491 13.0528 0.101746 13.5651H6.26098H6.26103V22.5408C7.23115 23.0701 8.28251 23.4688 9.39148 23.7146V13.5653V13.5652H23.8986C23.9653 13.0528 24.0002 12.5304 24.0002 11.9999C24.0002 11.4694 23.9653 10.9471 23.8986 10.4347Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8514">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
