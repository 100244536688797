import React from 'react';

export const AlbanianFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1624_30287)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#D80027"
        />
        <path
          d="M18.782 8.90749H14.4355C14.7038 8.62652 14.8689 8.24613 14.8689 7.82692C14.8689 6.9625 14.1681 6.26172 13.3037 6.26172C12.7591 6.26172 12.2797 6.53997 11.9994 6.96189C11.719 6.53997 11.2396 6.26172 10.695 6.26172C9.83061 6.26172 9.12983 6.9625 9.12983 7.82692C9.12983 8.24613 9.29497 8.62656 9.56328 8.90749H5.2168C5.2168 10.0601 6.22072 10.9944 7.37323 10.9944H7.30367C7.30367 12.1471 8.23803 13.0814 9.39069 13.0814C9.39069 13.4547 9.48908 13.8047 9.66083 14.1077L7.92973 15.8388L9.25789 17.1669L11.1419 15.283C11.2157 15.31 11.292 15.3317 11.3709 15.3465L10.2324 17.9169L11.9994 19.8269L13.7663 17.9168L12.6279 15.3464C12.7068 15.3316 12.7831 15.31 12.8569 15.2829L14.7409 17.1668L16.069 15.8387L14.3379 14.1076C14.5097 13.8046 14.6081 13.4546 14.6081 13.0813C15.7607 13.0813 16.695 12.147 16.695 10.9943H16.6255C17.778 10.9944 18.782 10.0601 18.782 8.90749Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30287">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
