import { IReservation } from 'types/app/reservations';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Statuses } from 'types/reservation';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Timeline from '../CustomReactCalendarTimeline';
import './_styles/CustomTimeline.scss';

import containerResizeDetector from '../CustomReactCalendarTimeline/resize-detector/container';
import { canReservationMove } from 'utils/reservation/reservations';
import { TimelineItem } from 'app/components/TimelineItem';
import { newHandleTimelineHeaders } from './_utils/newHandleTimelineHeaders';
import { CustomTimelineMarkers } from './_components/CustomTimelineMarkers';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Translation } from 'react-i18next';
import bookingActions from 'redux/actions/app/booking';
import appActions from 'redux/actions/app/app';
import { inRange, map } from 'lodash';
import moment from 'moment-timezone';
import { timestamp } from 'utils/date-time/timestamp';
import { RoomItem } from './_components/RoomItem';
import { TableItem } from './_components/TableItem';
import { ITimelineItem } from 'app/components/CustomTimeline/_types/customTimeline';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';
import { useReservationBadgeConditions } from 'hooks/useReservationBadgeConditions';
import {
  selectCalendarReservationList,
  selectFilteredReservationList,
  selectReservationListByFilters,
} from 'redux/selectors/reservation';
import { useStatus } from 'hooks/useStatus';
import { StyledTypography } from 'app/components/TextElements/StyledTypography';
import { selectActiveRooms, selectActiveTables } from 'redux/selectors/tableManagement';
import { useDevice } from 'hooks/useDevice';

export const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  newReservationItemId: 'new',
};

const overlappedStatuses = [Statuses.Cancelled, Statuses.WaitingList, Statuses.NoShow];

export const CustomTimeline = () => {
  const scrollRef: any = useRef(null);
  const [scrollMove, setScrollMove] = useState(false);
  const [clickTimestamp, setClickTimestamp] = useState(0);
  const { isReadOnly } = useStatus();
  const device = useDevice();
  const isTouchDevice = device.isTouchable();

  const rooms = useAppSelector(selectActiveRooms);
  const tables = useAppSelector(selectActiveTables);
  const app = useAppSelector((state) => state.app.app);
  const settings = useAppSelector((state) => state.app.settings);
  const theme = useAppSelector((state) => state.theme);
  const { auto_arrival, original_time, calendar_full_view } = useAppSelector(
    (state) => state.app.preferences,
  );
  const calendarMinWidth = 1800;
  const dispatch = useAppDispatch();
  const [now, setNow] = useState(0);

  const interval = settings.reservation.interval;
  const intervalInMinutes = interval / 60;
  const intervalInHours = intervalInMinutes / 60;

  const reservations = useAppSelector(selectFilteredReservationList);
  const calendarReservations = useAppSelector(selectCalendarReservationList);
  const filteredReservations = useAppSelector(selectReservationListByFilters(calendarReservations));

  useEffect(() => {
    setNow(moment().utc().valueOf() + settings.time_and_language.time_zone_offset * 60 * 1000);

    const timeInterval = setInterval(() => {
      const today =
        moment().utc().valueOf() + settings.time_and_language.time_zone_offset * 60 * 1000;
      setNow(today);
    }, 60000);

    return () => clearInterval(timeInterval);
  }, [settings.time_and_language.time_zone_offset]);

  const [expandedGroups, setExpandedGroups] = useState<{
    [id: number]: boolean;
  }>({});

  const [newReservation, setNewReservation] = useState<any>({});

  const openNewReservationDialog = (item: any) => {
    if (item) {
      dispatch(bookingActions.updateBooking(item.reservation));
      dispatch(appActions.openModal());
    }
  };

  useEffect(() => {
    const initialExpandedGroups: {
      [id: number]: boolean;
    } = {};
    rooms.forEach((room) => {
      initialExpandedGroups[room.id] = !room.collapsed;
    });

    setExpandedGroups(initialExpandedGroups);
  }, [rooms]);

  const toggleGroup = (id: number) => {
    setExpandedGroups((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const statusVisibilityOverrides = {
    [Statuses.Blocked]: {
      showNoOfGuests: false,
      showOrigin: false,
      showComment: false,
      showVip: false,
      showBigSpender: false,
      showTime: true,
    },
    [Statuses.WalkIn]: {
      showNoOfGuests: true,
      showOrigin: false,
      showComment: false,
      showVip: false,
      showBigSpender: false,
      showTime: true,
    },
  };

  const getStatusClass = ({ status, start_time }: IReservation) => {
    if (
      status === Statuses.Confirmed &&
      timestamp(start_time).toMilliseconds < now &&
      !!auto_arrival
    ) {
      return `event-theme-${theme.mode} event-${Statuses.Arrived} `;
    }
    return `event-theme-${theme.mode} event-${status} `;
  };

  const getReservationBadgeConditions = useReservationBadgeConditions({
    big_spender_tag: settings.reservation.big_spender_tag,
    original_time,
    statusVisibilityOverrides,
  });

  const titleExcludedStatuses = [Statuses.WalkIn, Statuses.Blocked];

  const timelineItems: ITimelineItem[] = useMemo(() => {
    return filteredReservations
      .sort((a, b) => overlappedStatuses.indexOf(b.status) - overlappedStatuses.indexOf(a.status))
      .map((reservation) => {
        return {
          id: reservation.id,
          title: titleExcludedStatuses.includes(reservation.status)
            ? snakeToCamelCase(reservation.status)
            : reservation?.client?.name,
          canMove: !getReservationBadgeConditions(reservation).isLocked,
          canResize: false,
          start: timestamp(reservation.start_time).toMilliseconds,
          end: timestamp(reservation.end_time).toMilliseconds,
          group: reservation.tables,

          reservation: reservation,
          guestCount: reservation.guests,
          interval,

          itemProps: {
            className: getStatusClass(reservation),
          },
          settings: {
            now: now,
            spinner: reservation?.isLoading || !reservation.created_at,
            badges: getReservationBadgeConditions(reservation),
          },
        } as ITimelineItem;
      });
  }, [filteredReservations, now, theme.mode, original_time, auto_arrival]);

  const roomsAndTables = useMemo(() => {
    let roomsAndTables: any = [];

    rooms.forEach((room) => {
      roomsAndTables.push({
        id: `${room.id}`,
        roomId: room.id,
        title: <RoomItem room={room} onClick={toggleGroup} isExpanded={expandedGroups[room.id]} />,
        height: 40,
        root: true,
      });

      const roomTables = tables
        .filter((table) => table.room_id === room.id && expandedGroups[room.id])
        .map((table) => ({
          id: `${table.id}`,
          tableId: table.id,
          height: 58,
          title: (
            <TableItem
              name={table.name}
              minGuests={table.min_guests}
              maxGuests={table.max_guests}
            />
          ),
          root: false,
          parent: table.room_id,
          booking_length: table.booking_length,
          covers_max: table.max_guests,
          covers_min: table.min_guests,
        }));

      roomsAndTables = roomsAndTables.concat(roomTables);
    });

    return roomsAndTables;
  }, [rooms, tables, expandedGroups]);

  const verticalLineClassNamesForGroups = (timeStart: number, timeEnd: number, group: any) => {
    const classes: any = [];
    const { blocked_times, time } = app.opening_hour;
    const currentTimeStart = moment(timeStart);

    classes.push('minutes-' + currentTimeStart.minute());

    if (
      timestamp(time.start).toMilliseconds > timeStart ||
      timestamp(time.end).toMilliseconds < timeEnd
    ) {
      classes.push('workday-break');
      return classes;
    }

    const data = blocked_times.find((blockedTime) => {
      return blockedTime.room_id == group.id;
    });

    if (!data || !data.times) {
      return classes;
    }

    const is_blocked = data.times.find((time) => {
      return timestamp(time).toMilliseconds === timeStart;
    });

    if (is_blocked) {
      classes.push('online-blocked');
    }

    return classes;
  };

  const handleCanvasClickStart = (
    groupId: string,
    time: number,
    event: MouseEvent | TouchEvent,
  ) => {
    if (isReadOnly) return;
    if (device.onTouchStart(event)) {
      setClickTimestamp(moment().valueOf());
      setScrollMove(true);
    }

    const room = roomsAndTables.find((x: any) => x.id === groupId);

    if (room.root) {
      return;
    }

    setNewReservation({
      id: keys.newReservationItemId,
      group: [groupId],
      start: time,
      end: time, //minus 1 second to avoid overlapping
      canMove: false,

      itemProps: {
        className: `event-${Statuses.Confirmed} `,
      },

      settings: {
        badges: {
          showTime: false,
          showNoOfGuests: false,
          showOrigin: false,
          showComment: false,
          showVip: false,
        },
      },
    });
  };

  const handleCanvasClickEnd = (groupId: string, time: number, event: MouseEvent | TouchEvent) => {
    if (isReadOnly) return;
    if (device.onTouchEnd(event) && scrollMove) {
      setScrollMove(false);
      return;
    }

    if (newReservation.group && newReservation.group.length > 0) {
      const table = roomsAndTables.find((x: any) => x.id === newReservation.group[0]);

      if (newReservation.end === newReservation.start) {
        newReservation.end = newReservation.start + table.booking_length * 60 * 1000;
      }

      if (newReservation.start > newReservation.end) return;

      openNewReservationDialog({
        reservation: {
          start_time: newReservation.start,
          end_time: newReservation.end,
          tables: newReservation.group,
        },
      });
      setNewReservation({});
    }
  };

  const isTableOccupied = (
    tableId: string,
    startTime: number,
    endTime: number,
    item: ITimelineItem,
  ) => {
    return [...reservations]
      .filter(
        (reservation) =>
          reservation.id !== item.reservation?.id &&
          !overlappedStatuses.includes(reservation.status),
      )
      .some(
        (r) =>
          r.tables.includes(tableId) &&
          r.start_time < timestamp(endTime).toSeconds &&
          r.end_time > timestamp(startTime).toSeconds,
      );
  };

  const handleMouseMove = (groupId: string, time: number, event: MouseEvent | TouchEvent) => {
    if (device.onTouchMove(event)) {
      if (!!clickTimestamp || !scrollMove) {
        if (moment().valueOf() - clickTimestamp > 500) {
          setScrollMove(false);
          setClickTimestamp(0);
        } else {
          setNewReservation({});
          setClickTimestamp(0);
          return;
        }
      } else if (scrollMove) {
        return;
      }
    }

    const room = roomsAndTables.find((x: any) => x.id === groupId);

    if (room.root) {
      return;
    }

    let groups: any = [];

    if (newReservation.group[0] === groupId) {
      groups = [newReservation.group[0]];

      const overlappingReservation = [...reservations].find(
        (r) =>
          r.tables.includes(groupId) &&
          r.start_time < timestamp(time).toSeconds &&
          r.end_time > timestamp(newReservation.start).toSeconds,
      );

      if (overlappingReservation) {
        time = Math.min(time, timestamp(overlappingReservation.start_time).toMilliseconds);
      }
    } else {
      const startGroupToAddIndex = roomsAndTables.findIndex(
        (x: any) => x.id === newReservation.group[0],
      );
      const groupIndex = roomsAndTables.findIndex((x: any) => x.id === groupId);

      groups = roomsAndTables.slice(startGroupToAddIndex, groupIndex + 1);

      groups = map(
        groups.filter((group: any) => {
          const isGroupInvalid = isTableOccupied(
            group.id,
            newReservation.start,
            time,
            newReservation,
          );

          return !group.root && !isGroupInvalid;
        }),
        'id',
      );
    }

    setNewReservation((prevState: any) => ({
      ...prevState,
      end: time,
      group: groups,
    }));
  };

  const handleItemClick = (itemId: number) => {
    const item = timelineItems.find((reservation) => reservation.id === itemId);
    openNewReservationDialog(item);
  };

  const handleItemDrag = () => {
    if (isReadOnly || isTouchDevice) {
      setScrollMove(true);
      return false;
    }
  };

  const handleItemMove = async (reservationId: string, dragTime: any, tableIds: string[]) => {
    if (isReadOnly || isTouchDevice) return;
    const item = timelineItems.find((r) => r.id === reservationId);

    if (!item) {
      return;
    }
    const endTime = dragTime + (item.end - item.start);

    if (
      timestamp(endTime).toSeconds > app.opening_hour.time.end ||
      timestamp(dragTime).toSeconds < app.opening_hour.time.start
    ) {
      return;
    }

    if (overlappedStatuses.includes(item.reservation.status)) {
      item.settings.spinner = true;
      item.canMove = false;
      item.end = endTime;
      item.start = dragTime;
      item.group = tableIds;

      await dispatch(
        bookingActions.updateReservationTime({
          id: Number(reservationId),
          start: timestamp(dragTime).toSeconds,
          end: timestamp(endTime).toSeconds,
          tables: tableIds,
        }),
      );
    } else {
      let isOccupied = false;

      tableIds.forEach((tableId: string) => {
        if (isTableOccupied(tableId, dragTime, endTime, item)) {
          isOccupied = true;
          return;
        }
      });

      if (isOccupied) {
        return;
      }

      item.settings.spinner = true;
      item.canMove = false;
      item.end = endTime;
      item.start = dragTime;
      item.group = tableIds;

      await dispatch(
        bookingActions.updateReservationTime({
          id: Number(reservationId),
          start: timestamp(dragTime).toSeconds,
          end: timestamp(endTime).toSeconds,
          tables: tableIds,
        }),
      );
    }
  };

  const currentTime = moment().unix();

  const handleScrollRef = (element: any) => {
    scrollRef.current = element;
  };

  useEffect(() => {
    const handleScrollMove = (event: any) => {
      if (!scrollMove) {
        event.preventDefault();
      }
    };

    const currentScroll = scrollRef.current;
    if (currentScroll) {
      currentScroll.addEventListener('touchmove', handleScrollMove, { passive: false });
    }

    return () => {
      if (currentScroll) {
        currentScroll.removeEventListener('touchmove', handleScrollMove, { passive: false });
      }
    };
  }, [scrollMove]);

  return app.opening_hour.time.rounded_end ? (
    <Scrollbars
      autoHide
      className={[
        'scroll-area timeline-container no-print',
        app.opening_hour.is_closed ? 'timeline-container--has-background' : '',
        `timeline-container--${theme.mode}`,
      ].join(' ')}
    >
      {!app.opening_hour.is_closed ? (
        <Timeline
          scrollRef={handleScrollRef}
          resizeDetector={containerResizeDetector}
          groups={roomsAndTables}
          items={[...timelineItems, newReservation]}
          keys={keys}
          style={
            calendar_full_view
              ? {}
              : {
                  minWidth: calendarMinWidth + 'px',
                }
          }
          sidebarWidth={150}
          sidebarContent={<div>Above The Left</div>}
          canMove={canReservationMove()}
          canResize="right"
          canSelect
          itemsSorted
          stackItems={false}
          visibleTimeStart={timestamp(app.opening_hour.time.rounded_start).toMilliseconds}
          visibleTimeEnd={timestamp(app.opening_hour.time.rounded_end).toMilliseconds}
          onCanvasClick={() => {}}
          onCanvasClickStart={handleCanvasClickStart}
          onCanvasClickEnd={handleCanvasClickEnd}
          onMouseMove={handleMouseMove}
          onCanvasDoubleClick={() => {}}
          onCanvasContextMenu={() => {}}
          onItemClick={handleItemClick}
          onItemSelect={handleItemClick}
          onItemContextMenu={() => {}}
          onItemMove={handleItemMove}
          onItemDrag={handleItemDrag}
          onItemResize={() => {}}
          onItemDoubleClick={handleItemClick}
          itemRenderer={TimelineItem}
          horizontalLineClassNamesForGroup={(group: any) => (group.root ? ['row-root'] : [])}
          verticalLineClassNamesForGroups={verticalLineClassNamesForGroups}
          timeSteps={{
            minute: intervalInMinutes,
            hour: intervalInHours,
          }}
          lineHeight={58}
          itemHeightRatio={1}
          dragSnap={interval * 1000}
        >
          {newHandleTimelineHeaders({
            increment: intervalInMinutes,
            dateFormatKey: settings.time_and_language.date_format,
          })}
          {inRange(
            currentTime,
            app.opening_hour.time.rounded_start - settings.time_and_language.time_zone_offset * 60,
            app.opening_hour.time.rounded_end - settings.time_and_language.time_zone_offset * 60,
          ) ? (
            <CustomTimelineMarkers now={now} />
          ) : (
            <></>
          )}
        </Timeline>
      ) : (
        <StyledTypography
          sx={{ textAlign: 'center', fontSize: '40px', wordBreak: 'break-word', padding: '24px' }}
        >
          <Translation>{(t) => t('reservationListRestaurantClosed')}</Translation>
        </StyledTypography>
      )}
    </Scrollbars>
  ) : null;
};
