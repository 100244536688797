import React from 'react';

export const SwedishFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_8645)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FFDA44"
        />
        <path
          d="M9.39084 10.435H23.8979C23.1309 4.54721 18.0964 0.000244141 11.9995 0.000244141C11.1035 0.000244141 10.2307 0.099291 9.39079 0.285479V10.435H9.39084Z"
          fill="#0052B4"
        />
        <path
          d="M6.26077 10.4349V1.45923C2.96574 3.25707 0.60694 6.55478 0.101486 10.4349H6.26077V10.4349Z"
          fill="#0052B4"
        />
        <path
          d="M6.26072 13.5657H0.101486C0.60694 17.4458 2.96574 20.7436 6.26077 22.5414L6.26072 13.5657Z"
          fill="#0052B4"
        />
        <path
          d="M9.39079 13.5657V23.7152C10.2307 23.9014 11.1035 24.0005 11.9995 24.0005C18.0964 24.0005 23.1309 19.4535 23.8979 13.5657H9.39079V13.5657Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1587_8645">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
