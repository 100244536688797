import React from 'react';

export const PortugueseFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1624_30581)">
        <path
          d="M0 12.0007C0 17.1602 3.2565 21.5587 7.82602 23.2542L8.86955 12.0006L7.82602 0.74707C3.2565 2.44263 0 6.8411 0 12.0007Z"
          fill="#6DA544"
        />
        <path
          d="M24.0002 12C24.0002 5.37262 18.6275 0 12.0002 0C10.5323 0 9.1262 0.264047 7.82617 0.746391V23.2536C9.1262 23.736 10.5323 24 12.0002 24C18.6275 24 24.0002 18.6274 24.0002 12Z"
          fill="#D80027"
        />
        <path
          d="M7.82623 16.1744C10.1314 16.1744 12.0001 14.3057 12.0001 12.0006C12.0001 9.69537 10.1314 7.82666 7.82623 7.82666C5.52106 7.82666 3.65234 9.69537 3.65234 12.0006C3.65234 14.3057 5.52106 16.1744 7.82623 16.1744Z"
          fill="#FFDA44"
        />
        <path
          d="M5.47852 9.9126V12.5213C5.47852 13.8179 6.52964 14.8691 7.82635 14.8691C9.12305 14.8691 10.1742 13.818 10.1742 12.5213V9.9126H5.47852Z"
          fill="#D80027"
        />
        <path
          d="M7.82559 13.3037C7.39406 13.3037 7.04297 12.9526 7.04297 12.5211V11.4777H8.60817V12.5211C8.60817 12.9526 8.25708 13.3037 7.82559 13.3037Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1624_30581">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
