import React from 'react';

interface IProps {
  name: string;
  minGuests: number;
  maxGuests: number;
}
export const TableItem = ({ name, minGuests, maxGuests }: IProps) => {
  return (
    <div className="table-details">
      <div className="table-name">{name}</div>
      <div className="covers-count">
        {minGuests}-{maxGuests}
      </div>
    </div>
  );
};
