import React from 'react';

export const PolishFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1587_6262)">
        <path
          d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0Z"
          fill="#F0F0F0"
        />
        <path d="M0 12C0 18.6274 5.37262 24 12 24C18.6274 24 24 18.6274 24 12" fill="#D80027" />
      </g>
      <defs>
        <clipPath id="clip0_1587_6262">
          <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
        </clipPath>
      </defs>
    </svg>
  );
};
