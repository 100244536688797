import React from 'react';

export const ArabicFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1587_8622)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#6DA544"
        />
        <path
          d="M6.78288 14.3477C6.78288 15.2121 7.48366 15.9129 8.34809 15.9129H13.0437C13.0437 16.6332 13.6277 17.2172 14.3481 17.2172H15.9133C16.6337 17.2172 17.2176 16.6332 17.2176 15.9129V14.3477H6.78288Z"
          fill="#F0F0F0"
        />
        <path
          d="M17.3474 6.78296V10.4351C17.3474 11.0105 16.8793 11.4786 16.3039 11.4786V13.0438C17.7424 13.0438 18.9126 11.8735 18.9126 10.4351V6.78296H17.3474Z"
          fill="#F0F0F0"
        />
        <path
          d="M6.13035 10.4349C6.13035 11.0103 5.66226 11.4784 5.08687 11.4784V13.0436C6.52532 13.0436 7.69556 11.8733 7.69556 10.4349V6.78271H6.13035V10.4349Z"
          fill="#F0F0F0"
        />
        <path d="M16.5655 6.78296H15.0003V10.4351H16.5655V6.78296Z" fill="#F0F0F0" />
        <path
          d="M12.6521 8.86993C12.6521 9.01379 12.535 9.13079 12.3912 9.13079C12.2474 9.13079 12.1303 9.01374 12.1303 8.86993V6.78296H10.5651V8.86993C10.5651 9.01379 10.4481 9.13079 10.3043 9.13079C10.1605 9.13079 10.0434 9.01374 10.0434 8.86993V6.78296H8.47816V8.86993C8.47816 9.87685 9.29735 10.696 10.3043 10.696C10.6921 10.696 11.0517 10.5742 11.3478 10.3672C11.6438 10.5741 12.0035 10.696 12.3913 10.696C12.4693 10.696 12.5461 10.6906 12.6216 10.6809C12.5107 11.1379 12.0992 11.4786 11.6086 11.4786V13.0438C13.0471 13.0438 14.2173 11.8735 14.2173 10.4351V8.86993V6.78296H12.6521V8.86993H12.6521Z"
          fill="#F0F0F0"
        />
        <path d="M10.826 11.478H8.47816V13.0432H10.826V11.478Z" fill="#F0F0F0" />
      </g>
      <defs>
        <clipPath id="clip0_1587_8622">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
