import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';
import { StyledCustomErrorMessage } from 'app/components/StyledComponents/StyledCustomErrorMessage';
import { SxProps } from '@mui/material';

type Props = {
  errors: FieldErrors;
  name: string;
  sx?: SxProps;
};
export const CustomErrorMessage = ({ errors, name }: Props) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => {
        return <StyledCustomErrorMessage>{message}</StyledCustomErrorMessage>;
      }}
    />
  );
};
