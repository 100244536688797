import React from 'react';

export const MalteseFlag = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1587_8569)">
        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#F0F0F0"/>
        <path d="M11.9998 -0.000488281C18.6272 -0.000488281 23.9998 5.37214 23.9998 11.9995C23.9998 18.6269 18.6272 23.9995 11.9998 23.9995" fill="#D80027"/>
        <path d="M8.34753 4.69631V3.1311H6.78233V4.69631H5.21708V6.26151H6.78233V7.82676H8.34753V6.26151H9.91273V4.69631H8.34753Z" fill="#ACABB1"/>
      </g>
      <defs>
        <clipPath id="clip0_1587_8569">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
